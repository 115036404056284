.skills-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1rem;
  padding: 10px;
}
.skills-item {
  padding: 20px;
  font-size: 30px;
  text-align: center;
  box-shadow: 0px 0px 1px #cacaca;
  border-radius: 8px;
}

.skills-item:hover {
  box-shadow: 0px 0px 2px cadetblue;
}

.skills-item:hover img {
  transform: scale(1.2);
  transition: 0.3s ease-in-out;
}

.skills-item img {
  height: 64px;
}

.skills-item p {
  margin-top: 12px;
  font-weight: 100;
  font-size: 18px;
}


@media (max-width: 768px) {
  .skills-container {
    grid-template-columns: auto auto;
    gap: 5px;
  }
  
  .skills-item {
    padding: 10px;
    font-size: 18px;
  }
}