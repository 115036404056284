nav{
  height: 64px;
  padding: 0px 10%;
}

#nav_container {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

#logo {
  font-family: fantasy;
}

#nav_links {
  display: flex;
}

.nav_link {
  text-decoration: none;
  list-style-type: none;
  padding: 0px 1rem;
  cursor: pointer;
}

.nav_link:hover {
  transform: scale(1.2);
  transition: 0.3s ease-in-out;
}

.nav_link a {
  color: #000;
  text-decoration-line: none;
}


@media (max-width: 768px) {
  #nav_links {
    display: none;
  }
  #nav_container {
    justify-content: center;
  }
}