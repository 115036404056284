@import url(./spacing.css);
@import url(./custom.css);
@import url(./flex.css);

html{
  scroll-behavior: smooth;
}

body {
  background-color: #fff;
  margin: 0px;
  padding: 0px;
}

p {
  margin: 0px;
  font-family: Calibri, sans-serif
}

.clr_prim {
  color: cadetblue;
}

.heading {
  font-size: 2.5vw;
  border-bottom: 2px solid cadetblue;
  font-family: fantasy;
  margin-bottom: 2rem;
}

.paragraph {
  margin: 0px;
  line-height: 1.6;
  font-size: 18px;
  text-align: justify;
}

.container section {
  margin-bottom: 100px;
}


/* --------------- Resume Button-------------- */
.primaryBtn {
  display: inline-flex;
  height: 40px;
  width: 300px;
  border: 2px solid cadetblue;
  margin: 20px 0px;
  color: #BFC0C0;
  text-transform: uppercase;
  text-decoration: none;
  font-size: .8em;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.primaryBtn a {
  color: #333;
  text-decoration: none;
  letter-spacing: 1px;
}

.resumeBtn {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.resumeBtn a {
  position: relative;
  transition: all .45s ease-Out;
}

#translate {
  transform: rotate(50deg);
  width: 100%;
  height: 250%;
  left: -300px;
  top: -30px;
  background: cadetblue;
  position: absolute;
  transition: all .3s ease-Out;
}

.resumeBtn:hover #translate {
  left: 0;
}

.resumeBtn:hover a {
  color: #2D3142;
}


/* ------------------------- */


.resumeBtnWithIcon {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.resumeBtnWithIcon a {
  position: relative;
  left: 0;
  transition: all .35s ease-Out;
}

#dub-arrow {
  width: 100%;
  height: 100%;
  background: cadetblue;
  left: -300px;
  position: absolute;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .35s ease-Out;
  bottom: 0;
  right: 0;
}

.resumeBtnWithIcon img {
  width: 20px;
  height: auto;
}

.resumeBtnWithIcon:hover #dub-arrow {
  left: 0;
}

.resumeBtnWithIcon:hover a {
  left: 300px;
}



@media (max-width: 768px) {
  .heading {
    font-size: 8vw;
  }

  .container section {
    margin-bottom: 50px;
  }
  
}