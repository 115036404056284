.journey_card {
  display: flex;
  margin-bottom: 30px;
}

.journey_card-left {
  padding-right: 20px;
}

.img_block {
  background-color: #fff;;
  padding: 20px;
  box-shadow: 0px 0px 2px #cacaca;
  border-radius: 12px;
}

.journey_card-left img {
  height: 28px;
}

.journey_card-right {
  padding-left: 16px;
}

.jrny_type {
  color: #808080;
  margin-bottom: 8px;
}

.jrny_title {
  color: #000;
  font-weight: 700;
  margin-bottom: 8px;
}

.jrny_role {
  color: #808080;
  margin-bottom: 8px;
}

.jrny_desc {
  color: #000;
  margin-bottom: 8px;
  line-height: 1.4;
}