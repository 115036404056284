main {
  padding: 0px 10%;
}

#into_container {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 64px);
}

#into_container::before {
  content: '';
  position: absolute;
  top: -100%;
  left: -100%;
  width: 150%;
  height: 200%;
  background: repeating-linear-gradient(
    45deg,
    cadetblue 10px,
    cadetblue 10px,
    cadetblue 20px
  );
  transform: rotate(-45deg);
  z-index: -10;
}

#into_container::after {
  content: '';
  position: absolute;
  bottom: -100%;
  left: -100%;
  width: 140%;
  height: 140%;
  background: repeating-linear-gradient(
    30deg,
    cadetblue 10px,
    cadetblue 20px
  );
  transform: rotate(-30deg);
  z-index: -10;
}

#intro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  flex-direction: column;
  background-image: url("../images/me.png");
  background-size: 50vh;
  background-repeat: no-repeat;
  background-position: right;
}

.welcome_text {
  font-size: 3.2vw;
  margin: 0px;
}

#smallMe {
  height: 128px;
  width: 128px;
  display: none;
}

.profile_logo:hover {
  transition: 0.3s ease-in-out;
  transform: scale(1.2);
  cursor: pointer;
}

@media (max-width: 768px) {

  #into_container {
    background-repeat: no-repeat;
    background-position: top;
    height: 50vh;
  }

  #into_container::before {
    background: none;
  }

  #into_container::after {
    background: none;
  }

  #intro {
    background-image: none;
    justify-content: center;
    align-items: center;
  }

  .welcome_text {
    font-size: 10vw;
    margin: 0px;
  }

  #smallMe {
    display: block;
  }

}