.projects_card {
  border-left: 3px solid cadetblue;
  padding: 10px;
  box-shadow: 0px 0px 2px #cacaca;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 1rem;
}

.projects_card:hover {
  box-shadow: 0px 0px 3px cadetblue;
  /* transform: scale(1.03); */
  transition: 0.2s ease-in-out;
}

.projects_card .block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.block .block_left {
  width: 40%;
}

.block .block_right {
  width: 55%;
}

.domain_tag {
  border-bottom: 2px solid cadetblue;
  padding: 4px;
  margin-bottom: 8px;
}

.webBadge {
  padding: 3px 9px;
  margin-right: 10px;
  border-radius: 8px;
  background-color: turquoise;
}

.mobileBadge {
  padding: 3px 9px;
  margin-right: 10px;
  border-radius: 8px;
  color: #fff;
  background-color: cadetblue;
}

.project_link {
  text-decoration: none;
  color: cadetblue;
  border-bottom: 1px solid cadetblue;
  padding: 4px 0px;
}

@media (max-width: 768px) {
  .projects_card .block {
    display: block
  }
  
  .block .block_left {
    width: 100%;
  }
  
  .block .block_right {
    width: 100%;
    margin-top: 16px;
  }
}